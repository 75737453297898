<template>
  <div class="new-vacation-container">
    <div class="field">
      <p>סניף</p>
      <InputText
        type="text"
        :placeholder="branche"
        disabled
        style="width:100%"
      />
    </div>
    <div class="field">
      <p>דוא"ל</p>
      <InputText type="text" v-model="values.email" style="width:100%" />
    </div>
    <div class="field">
      <p>מי יוצא לחופשה?</p>
      <InputText
        type="text"
        v-model="values.fullName"
        placeholder="שם מלא"
        style="width:100%"
      />
    </div>
    <div class="field">
      <p>תאריך יציאה לחופשה</p>
      <input type="date" v-model="values.startDate" style="width:100%" />
    </div>

    <div class="field">
      <p>תאריך חזרה לעבודה</p>
      <input type="date" v-model="values.endDate" style="width:100%" />
    </div>

    <div class="field">
      <p>שם המחליף בזמן החופשה</p>
      <InputText
        type="text"
        v-model="values.alternativeWorker"
        placeholder="שם מלא"
        style="width:100%"
      />
    </div>
    <div class="field">
      <Button
        label="שלח טופס"
        class="p-button-success"
        style="width:100%"
        @click="handleSubmit"
      />
    </div>
  </div>

  <!-- JUST FOR MAIL -->
  <div class="form-email" id="form-email" v-show="false">
    <table
      style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
    >
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סניף/שם
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.branche }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
          דוא"ל
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
          {{ values.email }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם העובד
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.fullName }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          תחילת חופשה
        </th>
        <td
          v-if="values.startDate instanceof Date"
          style="border:1px solid #dddddd; text-align:right; padding: 8px;"
        >
          {{ values.startDate.toLocaleDateString("he") }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          חזרה לעבודה
        </th>
        <td
          v-if="values.endDate instanceof Date"
          style="border:1px solid #dddddd; text-align:right; padding: 8px;"
        >
          {{ values.endDate.toLocaleDateString("he") }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          עובד חלופי
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.alternativeWorker }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import {alert} from '../../../../Methods/Msgs'
import { ref } from "@vue/reactivity";
export default {
  props: ["branche", "email"],
  emits: ["closeDialog"],
  components: { InputText },
  setup(props, { emit }) {
    const error = ref("");
    const values = ref({
      branche: props.branche,
      email: props.email,
      fullName: "",
      startDate: "",
      endDate: "",
      alternativeWorker: "",
      createdAt: new Date(),
      status: "חדש",
      comment: ""
    });
    const validation = () => {
      for (var key in values.value) {
        if (key == "email") {
          if (
            values.value[key].length == 0 ||
            !values.value[key].includes("@")
          ) {
            error.value = "נא למלא כתובת מייל תקינה";
            return false;
          }
        }
        if (key == "fullName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שם מלא של מי שיוצא לחופשה";
            return false;
          }
        }
        if (key == "startDate") {
          if (values.value[key].length == 0) {
            error.value = "נא להזין תאריך יציאה לחופשה";
            return false;
          }
        }
        if (key == "endDate") {
          if (values.value[key].length == 0) {
            error.value = "נא להזין תאריך חזרה לעבודה";
            return false;
          }
        }
        if (key == "alternativeWorker") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא של המחליף בזמן החופשה";
            return false;
          }
        }
      }
      return true;
    };
    const handleSubmit = async () => {
      if (!validation()) {
        alert("error","אופס...",error.value)
      } else {
        values.value.startDate = new Date(values.value.startDate);
        values.value.endDate = new Date(values.value.endDate);
        await sroreInDb();
        sendEmailToUser();
        sendEmailToCharge();
        alert("success","הצלחה","הטופס נשלח בהצלחה")
        .then(() => {
          emit("closeDialog");
        });
      }
    };
    const sroreInDb = async () => {
      const doc = projectFirestore
        .collection("Applications") 
        .doc("5aVcwUtL4SpWyiR5qHhE")
        .collection("Vacation_requests")
        .doc();
      await doc.set({ ...values.value, id: doc.id });
    };

    function sendEmailToUser() {
        projectFunctions.httpsCallable('send_email')({
            html: `<div>
              <h3>
                  בקשתך לחופשה מתאריך ${values.value.startDate.toLocaleDateString(
                    "he"
                  )}
              </h3>
              <h3>
                  תאריך חזרה ${values.value.endDate.toLocaleDateString("he")}
              </h3>
              <h3>
                  לעובד ${values.value.fullName}
              </h3>
              <h3>
                  נשלחה בהצלחה לטיפול
              </h3>
              <h3 style="color:red;">
                  נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
              </h3>
          </div>`,
            destinations:[`${values.value.email}`],
            from:"מערכת בקשה לחופשה",
            title:"בקשת חופשה לעובד"
        }) 
    }
    function sendEmailToCharge() {
      projectFunctions.httpsCallable('send_email')({
          html: document.getElementById("form-email").innerHTML,
          destinations:["tomer@rosman.co.il"],
          from:"נפתחה בקשת חופשה חדשה",
          title:`בקשת חופשה חדשה לעובד/ת ${values.value.fullName} מסניף ${values.value.branche}`
      })
    }
    return { values, handleSubmit };
  }
};
</script>

<style scoped>
.new-vacation-container {
  width: 80vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.field {
  width: 80%;
  margin-top: 20px;
}
input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
p {
  font-size: 1rem;
  font-weight: bold;
}
</style>
