<template>
  <div class="vacation_request_main">
      <Button @click="show_new_vacation=!show_new_vacation"  style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="טופס בקשה לחופשה" class="p-button-success" />
      <Button v-if="user_role>=99" @click="show_manage_vacations=!show_manage_vacations"  style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="ניהול טפסי חופשה" class="p-button-warning" />
      <Dialog header="טופס בקשה לחופשה"  v-model:visible="show_new_vacation">
        <NewVacation
            :branche="userProfile.branche"
            :email="userProfile.email"
            @closeDialog="show_new_vacation = !show_new_vacation"
        />
      </Dialog>
      <Dialog  header="ניהול טפסי חופשה"  v-model:visible="show_manage_vacations">
         <ManagerVacationRequests />
      </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import { ref } from '@vue/reactivity';
import NewVacation from '../vacation_requests/components/NewVacation.vue'
import ManagerVacationRequests from '../vacation_requests/components/ManagerVacationRequests.vue'
import store from '../../../store';
import { computed } from '@vue/runtime-core';
export default {
    components:{Dialog,NewVacation,ManagerVacationRequests},
    setup(){
        const show_new_vacation = ref(false)
        const show_manage_vacations = ref(false)
        const user_role = ref(computed(()=>{
            return store.getters.role
        }))
        const userProfile = ref(computed(() => {
            return store.getters.user;
        }));
        return{user_role,show_new_vacation,show_manage_vacations,userProfile}
    }
}
</script>

<style>
    .vacation_request_main{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>