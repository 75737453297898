<template>
  <div v-if="desktop" class="manager-vacation-container">
    <div class="header">
      <div class="search">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="search" type="text" placeholder="חיפוש" />
        </span>
      </div>
      <div class="btn">
        <Button
          label="חדשים"
          class="p-button-success"
          @click="status_filter = 'חדש'"
        />
      </div>
      <div class="btn">
        <Button
          label="מאושרים"
          class="p-button-warning"
          @click="status_filter = 'מאושר'"
        />
      </div>
      <div class="btn">
        <Button
          label="לא מאושרים"
          class="p-button-info"
          @click="status_filter = 'לא מאושר'"
        />
      </div>
      <div class="btn">
        <Button
          label="הכל"
          class="p-button-secondary"
          @click="status_filter = ''"
        />
      </div>
    </div>
    <div class="content">
      <table>
        <tr>
          <th @click="handleSortTable('נוצר בתאריך', !sortedByclick.order)">
            נוצר בתאריך
          </th>
          <th @click="handleSortTable('סניף', !sortedByclick.order)">סניף</th>
          <th @click="handleSortTable('דואל', !sortedByclick.order)">דוא"ל</th>
          <th @click="handleSortTable('שם מלא', !sortedByclick.order)">
            שם מלא
          </th>
          <th @click="handleSortTable('תחילת חופשה', !sortedByclick.order)">
            תחילת חופשה
          </th>
          <th @click="handleSortTable('סיום חופשה', !sortedByclick.order)">
            סיום חופשה
          </th>
          <th @click="handleSortTable('עובד חלופי', !sortedByclick.order)">
            עובד חלופי
          </th>
          <th @click="handleSortTable('סטטוס', !sortedByclick.order)">
            סטטוס טיפול
          </th>
          <th>משוב</th>
        </tr>
        <template v-for="detail in sortedDetails" :key="detail.id">
          <tr>
            <td>{{ detail.createdAt.toDate().toLocaleDateString("he") }}</td>
            <td>{{ detail.branche }}</td>
            <td>{{ detail.email }}</td>
            <td>{{ detail.fullName }}</td>
            <td>{{ detail.startDate.toDate().toLocaleDateString("he") }}</td>
            <td>{{ detail.endDate.toDate().toLocaleDateString("he") }}</td>
            <td>{{ detail.alternativeWorker }}</td>
            <td
              v-if="detail.status == 'חדש'"
              style="color:green; text-align:center;"
            >
              {{ detail.status }}
            </td>
            <td
              v-if="detail.status == 'לא מאושר'"
              style="color:red; text-align:center;"
            >
              {{ detail.status }}
            </td>
            <td
              v-if="detail.status == 'מאושר'"
              style="color:blue; text-align:center;"
            >
              {{ detail.status }}
            </td>
            <td><i class="pi pi-reply" @click="handleReqeust(detail)"></i></td>
          </tr>
        </template>
      </table>
    </div>

    <Dialog header="משוב" v-model:visible="showMashov">
      <div class="mashov-container">
        <div class="explaination">
          <h4>במסך זה עליך לסיים את תהליך בקשת החופשה במערכת</h4>
          <h4>
            כדי לסיים את תהליך הבקשה עליך למלא את השדה מתחת בהערותיך לבקשה זו
          </h4>
          <h4>כאשר הינך מסיים לכתוב את הערותך לחץ על כפתור סיים תהליך</h4>
          <h4>מייל לאישור סיום התהליך יישלח לפותח/ת הקריאה</h4>
          <h2>נא לשים לב!</h2>
          <h2>
            באפשרותך למחוק את הקריאה ע"י לחיצה על כפתור מחק הנמצא בתחתית החלון
          </h2>
        </div>
        <div class="field">
          <p>הערותיך לקריאת שינוע זו:</p>
          <Textarea
            v-model="current_request_choosed.comment"
            rows="5"
            cols="30"
            style="width:100%;"
          />
        </div>
        <div class="field">
          <p>סמן סטטוס טיפול:</p>
          <Dropdown
            v-model="current_request_choosed.status"
            :options="statusOptions"
            style="width:100%;"
            placeholder="סמן סטטוס טיפול"
          />
        </div>
        <div class="send-btn">
          <Button
            @click="handleFinishMashov"
            label="סיים תהליך"
            class="p-button-success"
            style="width:100%;"
          />
        </div>
        <div class="delete-btn">
          <Button
            @click="handleDelete"
            label="מחיקה"
            class="p-button-danger"
            style="width:100%;"
          />
        </div>
      </div>
    </Dialog>
  </div>
  <OnlyForDesktop v-if="!desktop" />
</template>

<script>
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import OnlyForDesktop from '../../../Not_responsible/OnlyForDesktop.vue'
import Dialog from "primevue/dialog";
import { ref } from "@vue/reactivity";
import { computed, onMounted, onUnmounted } from "@vue/runtime-core";
import {alert,alert_confirm} from '../../../../Methods/Msgs'
export default {
  components: { InputText, Dialog, Textarea, Dropdown,OnlyForDesktop },
  setup() {
    const desktop = ref(true)
    const statusOptions = ref(["חדש", "מאושר", "לא מאושר"]);
    const showMashov = ref(false);
    const search = ref("");
    const details = ref([]);
    const status_filter = ref("חדש");
    const current_request_choosed = ref({});
    const filterBySearch = ref(
      computed(() => {
        if (search.value == "") {
          if (status_filter.value.length > 0) {
            return details.value.filter(detail => {
              return detail.status == status_filter.value;
            });
          }
          return details.value;
        }
        return details.value.filter(detail => {
          if (status_filter.value.length > 0) {
            if (
              detail.status == status_filter.value &&
              detail.branche.includes(search.value)
            )
              return detail;
            if (
              detail.status == status_filter.value &&
              detail.email.includes(search.value)
            )
              return detail;
            if (
              detail.status == status_filter.value &&
              detail.fullName.includes(search.value)
            )
              return detail;
            if (
              detail.status == status_filter.value &&
              detail.startDate
                .toDate()
                .toLocaleDateString("he")
                .includes(search.value)
            )
              return detail;
            if (
              detail.status == status_filter.value &&
              detail.endDate
                .toDate()
                .toLocaleDateString("he")
                .includes(search.value)
            )
              return detail;
            if (
              detail.status == status_filter.value &&
              detail.alternativeWorker.includes(search.value)
            )
              return detail;
            if (
              detail.status == status_filter.value &&
              detail.status.includes(search.value)
            )
              return detail;
          } else {
            if (detail.branche.includes(search.value)) return detail;
            if (detail.email.includes(search.value)) return detail;
            if (detail.fullName.includes(search.value)) return detail;
            if (
              detail.startDate
                .toDate()
                .toLocaleDateString("he")
                .includes(search.value)
            )
              return detail;
            if (
              detail.endDate
                .toDate()
                .toLocaleDateString("he")
                .includes(search.value)
            )
              return detail;
            if (detail.alternativeWorker.includes(search.value)) return detail;
            if (detail.status.includes(search.value)) return detail;
          }
        });
      })
    );
    const sortedByclick = ref({
      name: "",
      order: false
    });
    const handleSortTable = (name, order) => {
      sortedByclick.value.name = name;
      sortedByclick.value.order = order;
    };
    const sortedDetails = ref(
      computed(() => {
        if (sortedByclick.value.name == "") return filterBySearch.value;

        if (sortedByclick.value.name == "נוצר בתאריך") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.createdAt.toDate() > b.createdAt.toDate()
                ? 1
                : b.createdAt.toDate() > a.createdAt.toDate()
                ? -1
                : 0;
            }
            return a.createdAt.toDate() > b.createdAt.toDate()
              ? -1
              : b.createdAt.toDate() > a.createdAt.toDate()
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "סניף") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.branche > b.branche ? 1 : b.branche > a.branche ? -1 : 0;
            }
            return a.branche > b.branche ? -1 : b.branche > a.branche ? 1 : 0;
          });
        }
        if (sortedByclick.value.name == "דואל") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.email > b.email ? 1 : b.email > a.email ? -1 : 0;
            }
            return a.email > b.email ? -1 : b.email > a.email ? 1 : 0;
          });
        }
        if (sortedByclick.value.name == "שם מלא") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.fullName > b.fullName
                ? 1
                : b.fullName > a.fullName
                ? -1
                : 0;
            }
            return a.fullName > b.fullName
              ? -1
              : b.fullName > a.fullName
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "תחילת חופשה") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.startDate.toDate() > b.startDate.toDate()
                ? 1
                : b.startDate.toDate() > a.startDate.toDate()
                ? -1
                : 0;
            }
            return a.startDate.toDate() > b.startDate.toDate()
              ? -1
              : b.startDate.toDate() > a.startDate.toDate()
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "סיום חופשה") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.endDate.toDate() > b.endDate.toDate()
                ? 1
                : b.endDate.toDate() > a.endDate.toDate()
                ? -1
                : 0;
            }
            return a.endDate.toDate() > b.endDate.toDate()
              ? -1
              : b.endDate.toDate() > a.endDate.toDate()
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "עובד חלופי") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.alternativeWorker > b.alternativeWorker
                ? 1
                : b.alternativeWorker > a.alternativeWorker
                ? -1
                : 0;
            }
            return a.alternativeWorker > b.alternativeWorker
              ? -1
              : b.alternativeWorker > a.alternativeWorker
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "סטטוס") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.status > b.status ? 1 : b.status > a.status ? -1 : 0;
            }
            return a.status > b.status ? -1 : b.status > a.status ? 1 : 0;
          });
        }
      })
    );

    const handleReqeust = detail => {
      showMashov.value = !showMashov.value;
      current_request_choosed.value = detail;
    };
    const handleFinishMashov = async () => {
      const id = current_request_choosed.value.id;
      await projectFirestore
        .collection("Applications")
        .doc("5aVcwUtL4SpWyiR5qHhE")
        .collection("Vacation_requests")
        .doc(id)
        .set(current_request_choosed.value,{merge:true});

        alert("success","הצלחה","הטופס עודכן בהצלחה")
        .then(() => {
            sendEmailToUser(current_request_choosed.value);
            showMashov.value = !showMashov.value;
        });
    };

    function sendEmailToUser(currentDetails) {
        projectFunctions.httpsCallable('send_email')({
            html: `<div>
              <h3>
                  בקשתך לחופשה מתאריך ${currentDetails.startDate
                    .toDate()
                    .toLocaleDateString("he")}
              </h3>
              <h3>
                  תאריך חזרה ${currentDetails.endDate
                    .toDate()
                    .toLocaleDateString("he")}
              </h3>
              <h3>
                  לעובד ${currentDetails.fullName}
              </h3>
              <h3>
                  סומנה ע"י הממונה כסטטוס: ${currentDetails.status}
              </h3>
              <h3>
                  הערות: ${
                    currentDetails.comment.length > 0
                      ? currentDetails.comment
                      : "אין"
                  }
              </h3>
              <h3 style="color:red;">
                  נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
              </h3>
          </div>`,
            destinations:[`${currentDetails.email}`],
            from:"מערכת בקשה לחופשה",
            title:`בקשת חופשה לעובד ${currentDetails.fullName} נסגרה`
        }) 
    }
    const handleDelete = () => {
      const id = current_request_choosed.value.id;
        alert_confirm("האם אתה בטוח שברצונך למחוק בקשה זו?")
        .then(async res => {
            if (res.isConfirmed) {
            await projectFirestore.collection("Applications").doc("5aVcwUtL4SpWyiR5qHhE")
            .collection("Vacation_requests").doc(id).delete();
            showMashov.value = !showMashov.value;
            }
      });
    };
    
    let unsub
    const retrieve_data_from_Db = () => {
      unsub = projectFirestore.collection("Applications")
        .doc("5aVcwUtL4SpWyiR5qHhE")
        .collection("Vacation_requests")
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              details.value.push(change.doc.data());
            }
            if (change.type === "modified") {
              const index = details.value.findIndex(
                detail => detail.id == change.doc.data().id
              );
              if (index != -1) {
                details.value[index] = change.doc.data();
              }
            }
            if (change.type === "removed") {
              const index = details.value.findIndex(
                detail => detail.id == change.doc.data().id
              );
              if (index != -1) {
                details.value.splice(index, 1);
              }
            }
          });
        });
    };


    window.addEventListener('resize', ()=>{
        if(window.innerWidth<600){
            desktop.value = false
        }else{
            desktop.value = true
        }
    });
   
   onMounted(() => {
      if(window.innerWidth<600){
                desktop.value = false
      }else{
          desktop.value = true
      }

      retrieve_data_from_Db();
    });

    onUnmounted(()=>{
      window.removeEventListener('resize', ()=>{
        if(window.innerWidth<600){
            desktop.value = false
        }else{
            desktop.value = true
        }
      });

      if(unsub){
        unsub()
      }
    })

    return {
      desktop,
      search,
      details,
      filterBySearch,
      sortedByclick,
      handleSortTable,
      handleReqeust,
      handleFinishMashov,
      handleDelete,
      sortedDetails,
      showMashov,
      current_request_choosed,
      statusOptions,
      status_filter
    };
  }
};
</script>

<style scoped>
.manager-vacation-container {
  width: 80vw;
  height: 75vh;
}
.header {
  width: 100%;
  height: 5vh;
  display: flex;
}
.header .search {
  margin-left: 5px;
}
.content {
  margin-top: 10px;
  width: 100%;
  height: 70vh;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 8px;
}
th {
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

tr:hover {
  background: lightskyblue;
}

i {
  cursor: pointer;
}

i:hover {
  color: #f7b00c;
}
.mashov-container {
  width: 70vw;
  height: 60vh;
}
.explaination h4,
.explaination h2 {
  margin-bottom: 5px;
}
.field {
  width: 90%;
  margin-bottom: 10px;
}
.send-btn {
  margin-top: 35px;
  width: 90%;
}
.delete-btn {
  position: absolute;
  width: 20%;
  bottom: 0;
  left: 0;
}
.btn {
  margin-left: 5px;
}
</style>
